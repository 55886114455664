<template>
  <div class="c_notice_pop">
    <van-popup v-model="show" class="c-notice-pop-container g-flex-column g-flex-align-center">
      <div class="c-notice-close" @click="confirm">
        <i class="iconfont icon-guanbi"></i>
      </div>
      <div class="c-notice-title">通知</div>
      <div class="c-notice-msg" v-html=" msg"></div>

       <div class="c-lookfor-btn">
        <operation-button :buttonObj="buttonObj" @clickItem="emitClick"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import OperationButton from './OperationButton.vue'
  // import { ImagePreview } from 'vant';
  // import { addEventListener } from '@/utils/index.js'
  export default {
    components: { OperationButton },
    data() {
      return {
        msg: '',
        show: false,
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '确认',
          event: 'confirm'
        }
      }
    },
    methods: {
      onShow(flag, item = { bullent: '' }) {
        this.msg = item.bullent
        this.show = flag
        // 富文本图片预览
        // this.$nextTick(() => {
        //   let imgList = document.getElementsByClassName('c_notice_pop')[0].getElementsByClassName('c-notice-msg')[0].getElementsByTagName('img')
        //   for(let i=0;i<imgList.length; i++){
        //     addEventListener(imgList[i], 'click', () => {
        //       ImagePreview([imgList[i].getAttribute('src')])
        //     })
        //   }
        // })
        
      },
      confirm() {
        this.show = false
      },
      emitClick(event) {
        if(!event) return
        this[event]()
      },
    }
  }
</script>

<style lang="scss">
.c_notice_pop {
  .c-notice-pop-container {
    min-width: 270px;
    max-width: 320px;
    min-height: 300px;
    max-height: 450px;
    padding: 26px 26px 13px 26px;
    border-radius: 12px;
    .c-notice-close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      .icon-guanbi {
        font-size: 18px;
        color: #B0B0B0;
      }
    }
    .c-notice-title {
      color: $main_color;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .c-notice-msg {
      flex: 1;
      overflow-y: auto;
      padding-top: 14px;
      color: #6F6F6F;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 1px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .c-lookfor-btn {
      align-self: stretch;
      padding: 20px 0px 0px;
    }
  }
}
</style>