<template>
  <div v-if="show" class="s_main_order_wait g-flex-column g-flex-align-center">
    <p class="s-order-wait-stop-title">正在接单</p>

    <div class="s-order-wait-count-down">
      <van-count-down :time="time" @finish="finishTime"/>
    </div>

    <span class="s-order-wait-tip">自动排队{{Number(this.$global.config.TASK.task_time) / 60 }}分钟，接单成功进行通知</span>

    <!-- 三个点动画 -->
    <div class="s-order-wait-dot-list g-flex-align-center">
      <div class="s-order-wait-item-dot"></div>
      <div class="s-order-wait-item-dot"></div>
      <div class="s-order-wait-item-dot"></div>
    </div>

    <!-- 按钮 -->
    <div class="s-order-wait-button">
      <operation-button :buttonObj="buttonObj" @clickItem="emitClick"/>
    </div>
  </div>
</template>

<script>
  import OperationButton from '../../components/OperationButton.vue'
  export default {
    components: { OperationButton },
    data() {
      return {
        show: false,
        time: Number(this.$global.config.TASK.task_time) * 1000,
        buttonObj: {
          claSS: 'c-btn-default',
          title: '停止接单',
          event: 'stopOrder'
        }
      }
    },
    methods: {
      onShow(flag = true, time = 0) {
        if(time) this.time = time - new Date().getTime()
        if(!flag) this.time = Number(this.$global.config.TASK.task_time) * 1000
        this.show = flag
      },

      finishTime() {
        this.show = false
        this.time =  Number(this.$global.config.TASK.task_time) * 1000
        this.$emit('emitStopOrder')
      },
      // 停止接单
      stopOrder() {
        this.$emit('emitStopOrder')
        // this.show = false
      },
      emitClick(event) {
        if(!event) return
        this[event]()
      }
    }
  }
</script>

<style lang="scss">
.s_main_order_wait {
  padding: 10px 0;
  .s-order-wait-stop-title {
    color: $black;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: bold;
  }
  .s-order-wait-count-down {
    padding: 10px 0;
    .van-count-down {
      color: $main_color;
      font-size: 16px;
    }
  }

  .s-order-wait-tip {
    font-size: 14px;
    color: $black;
    letter-spacing: 2px;
  }

  .s-order-wait-dot-list {
    padding: 10px 0;
    .s-order-wait-item-dot {
      width: 10px;
      height: 10px;
      margin: 0 10px;
      border-radius: 50%;
      &:nth-of-type(1) {
        animation: changeDotColor1 linear 3s infinite;
      }
      &:nth-of-type(2) {
        animation: changeDotColor2 linear 3s infinite;
      }
      &:nth-of-type(3) {
        animation: changeDotColor3 linear 3s infinite;
      }
    }
  }

  .s-order-wait-button {
    align-self: stretch;
  }
}
</style>