<template>
  <div class="s_main_order_select_pop">
    <van-popup v-model="show" :safe-area-inset-bottom="true" position="bottom" :style="{ height: 'auto' }" class="s-main-pop-container">
      <div class="s-main-pop">
        <!-- 关闭图标 -->
        <div class="s-main-pop-close" @click="onClose">
          <i class="iconfont icon-guanbi"></i>
        </div>

        <!-- 用户名 -->
        <div class="s-main-pop-person g-flex-justify-center g-flex-align-center">
          <div class="s-main-pop-person-left g-flex-justify-center g-flex-align-center">
            <i class="iconfont" :class="form.icon"></i>
            <span class="s-main-pop-person-name">{{form.account}}</span>
          </div>
          <div class="s-main-pop-person-qiehuan g-flex-align-center" @click="qiehuanClick">
            <i class="iconfont icon-qiehuan"></i>
            <span>去切换</span>
          </div>
        </div>

        <!-- 筛选列表 -->
        <div class="s-main-pop-list g-flex-column g-flex-align-start">
          <!-- 每一个筛选条件 -->
          <!-- <div class="s-main-pop-item s-main-pop-account-item">
            <p class="s-pop-item-title">选择账号</p>
            <ul class="s-pop-item-account-list">
              <li class="s-pop-item-account-list-item g-flex-align-center">
                <div class="s-pop-item-account-list-item-left g-flex-align-center">
                   <i class="iconfont icon-changyonglogo25"></i>
                   <span class="s-main-pop-person-name">宝爱豆1314123</span>
                </div>
                <div class="s-pop-item-account-list-item-right">
                  <i class="iconfont icon-dui1"></i>
                </div>
              </li>
              <li class="s-pop-item-account-list-item g-flex-align-center">
                <div class="s-pop-item-account-list-item-left g-flex-align-center">
                   <i class="iconfont icon-changyonglogo25"></i>
                   <span class="s-main-pop-person-name">宝爱豆1314123</span>
                </div>
                <div class="s-pop-item-account-list-item-right">
                </div>
              </li>
            </ul>
          </div> -->

          <!-- 每一个筛选条件 -->
          <div class="s-main-pop-item">
            <!-- 筛选标题 -->
            <p class="s-pop-item-title">选择任务设备</p>
            <!-- 每个筛选条件下的列表 -->
            <div class="s-pop-item-type-list">
            <!-- 每个筛选条件下的列表项 -->
              <div class="s-pop-type-item g-flex-align-center g-flex-justify-center" v-for="(item,index) in facilityList" :key="index" @click="facilityItemClick(item, index)">
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>

          <!-- 每一个筛选条件 -->
          <div class="s-main-pop-item">
            <!-- 筛选标题 -->
            <p class="s-pop-item-title">选择价格区间</p>
            <!-- 每个筛选条件下的列表 -->
            <div class="s-pop-item-type-list g-flex-wrap g-flex-align-center">
            <!-- 每个筛选条件下的列表项 -->
              <div class="s-pop-type-item s-pop-type-item-money g-flex-align-center g-flex-justify-center" :class="index === priceTypeIndex ? 's-pop-type-item-active': ''"  v-for="(item,index) in priceTypeList" :key="index" @click="priceTypeItemClick(item, index)">
                <span>{{item.price}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 确认按钮 -->
      <operation-button :buttonObj="buttonObj" @clickItem="emitClick" :isPadding="false"/>

    </van-popup>
  </div>
</template>

<script>
  import OperationButton from '../../components/OperationButton.vue'
  import { apiSelectAccount } from '@/utils/api.js';
  export default {
    components: { OperationButton },
    data() {
      return {
        taskTypeList: [
          { name: '垫付优先 ' },
          { name: '浏览优先 ' }
        ],
        taskTypeIndex: 0,

        facilityList: [
          { name: '手机任务' }
        ],
        facilityIndex: 0,

        form: {
          id: '',
          account: '',
          icon: '',
          title: '',
          price: '',
          user_account_id: '',
          price_limit: 0
        },
        priceTypeList: [
          { price: '不限', value: 0 },
          { price: '100以下', value: 100 },
          { price: '200以下', value: 200 },
          { price: '500以下', value: 500 },
          { price: '1000以下', value: 1000 },
          { price: '2000以下', value: 2000 },
          { price: '5000以下', value: 5000 },
          { price: '10000以下', value: 10000 },
        ],
        priceTypeIndex: 0,

        show: false,
        buttonObj: {
            claSS: 'c-btn-default',
            title: '确认',
            event: 'apiSelectAccountHandel'
        }
      }
    },
    methods: {
      onShow(obj) {
        console.log(obj)
        this.form = obj
        let target = this.priceTypeList.find(item => item.value == obj.price_limit) 
        if(target) this.priceTypeIndex = this.priceTypeList.indexOf(target)
        this.show = true
      },
      onClose() {
        this.show = false
      },
      
      emitClick(event) {
        if(!event) return
        this[event]()
      },

      // 点击切换
      qiehuanClick() {
        let type = 0
        if(this.form.id == 1) type = '0' 
        if(this.form.id == 2) type = '1' 
        if(this.form.id == 3) type = '2' 
        this.$router.push({ name: 'bindaccountlist', params: { type: type, id: this.form.id }})
      },

      // 任务每一项点击
      taskTypeItemClick(item, index) {
        console.log(item, index)
        this.taskTypeIndex = index
      },

      // 设备类型每一项点击
      facilityItemClick(item, index) {
        this.facilityIndex = index
      },

      // 价格每一项点击
      priceTypeItemClick(item, index) {
        this.priceTypeIndex = index
      },

      // 点击确认 切换账号接单
      async apiSelectAccountHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiSelectAccount({ id: this.form.user_account_id, price_limit: this.priceTypeList[this.priceTypeIndex].value })
        if(!success) return
        this.form.price_limit = this.priceTypeList[this.priceTypeIndex].value
        this.$toast(data.msg)
        this.show = false
        console.log(data)
      },
    }
  }
</script>

<style lang="scss">
.s_main_order_select_pop {
  .s-main-pop-container {
    padding: 16px 10px 20px 10px;
    background: #F1F1F1;
    .s-main-pop {
      padding: 0 10px;
      position: relative;

      .s-main-pop-close {
        position: absolute;
        right: 0px;
        top: -5px;
        padding: 5px;
        .icon-guanbi {
          font-size: 18px;
          color: #B0B0B0;
        }
      }

      .s-main-pop-person {
        .s-main-pop-person-left {
          .iconfont {
            font-size: 26px;
            color: $main_color;
          }
          .s-main-pop-person-name {
            font-size: 15px;
            color: #292929;
            padding-left: 5px;
            letter-spacing: 1px;
            @include textManyOverflow()
          }
        }

        .s-main-pop-person-qiehuan {
          min-width: 80px;
          margin-left: 15px;
          font-size: 14px;
          color: $main_color;
          .iconfont {
            font-size: 18px;
            padding: 0 5px;
          }
        }
      }

      .s-main-pop-list {
        padding-bottom: 30px;
        .s-main-pop-item {
          padding-top: 30px;
          &.s-main-pop-account-item {
            width: 100%;
          }
          .s-pop-item-title {
            font-size: 14px;
            font-weight: bold;
            color: #626262;
          }
          .s-pop-item-account-list {
            width: 100%;
            margin-top: 10px;
            border-radius: 4px;
            background: $main_color;
            padding: 5px 10px;
            .s-pop-item-account-list-item {
              width: 100%;
              padding: 4px 10px;
              border-bottom: 1px solid $white;
              &:nth-last-of-type(1) {
                border-bottom: none;
              }
              .s-pop-item-account-list-item-left {
                flex: 1;
                .iconfont {
                  font-size: 26px;
                  color: $white;
                }
                .s-main-pop-person-name {
                  color: $white;
                  font-size: 15px;
                  padding-left: 10px;
                  letter-spacing: 1px;
                }
              }
              .s-pop-item-account-list-item-right {
                color: $white;
                padding: 0 10px;
                .iconfont {
                  font-size: 22px;
                }
              }
            }
          }
          .s-pop-item-type-list {
            .s-pop-type-item {
              margin-top: 18px;
              margin-right: 14px;
              min-width: 70px;
              min-height: 35px;
              background:#F1F1F1;
              border: 1px solid $main_color;
              border-radius: 5px;
              span {
                color: $main_color;
                font-size: 14px;
                letter-spacing: 1px;
                padding: 6px;
              }
              &.s-pop-type-item-money {
                margin-right: 12px;
                min-height: 30px;
              }
              &.s-pop-type-item-active {
                background: $main_color;
                span {
                  color: $white;
                }
              }
            }
          }
        }
      }

    }
  }
}
</style>