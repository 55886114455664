<template>
  <div class="v_main g-flex-column">
    <head-back :isBack="false">
      <template>
        <span>首页</span>
      </template>
    </head-back>
    <div class="v-main-content g-ios-scrolling">
      <div class="v-main-top g-flex-column g-flex-align-center g-flex-justify-center" @click="$router.push({ name: 'balance' })">
        <div class="v-main-top-list g-flex-align-center">
          <div class="v-main-top-list-item g-flex-column g-flex-align-center">
            <p class="v-main-top-list-item-title">佣<span>金余额</span></p>
            <p class="v-main-top-list-item-value">{{totalMoney.commission | filtersInt }}<span>{{totalMoney.commission | filtersFloat }}</span></p>
          </div>
          <div class="v-main-top-list-item g-flex-column g-flex-align-center">
            <p class="v-main-top-list-item-title">本<span>金余额</span></p>
            <p class="v-main-top-list-item-value">{{totalMoney.principal | filtersInt }}<span>{{totalMoney.principal | filtersFloat }}</span></p>
          </div>
        </div>
      </div>

      <!-- 今日收入 -->
      <div class="v-main-total-list g-flex-align-center g-flex-justify-center">
        <div class="v-total-item">
          <span>今日收入</span>
          <span>{{totalMoney.today_money}}</span>
        </div>
        <div class="v-total-item">
          <span>任务收入</span>
          <span>{{totalMoney.task_money}}</span>
        </div>
        <div class="v-total-item">
          <span>推广奖励</span>
          <span>{{totalMoney.spread_money}}</span>
        </div>
      </div>
      
      <!-- 广播 -->
      <van-notice-bar class="v-notice" left-icon="volume-o" :scrollable="false" background="#ffffff" color="#F9436B" v-if="noticeList[0].bullent">
        <van-swipe vertical class="v-notice-swipe" :autoplay="2000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="noticeItemClick(item)">
            <div class="v-notice-item">
              <p>{{item.bullent_title}}</p>
              <span>更多</span>
            </div>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>

      <!-- 主页底部 -->
      <div class="v-main-bottom-content">
        <!-- 底部内容 -->
        <div class="v-main-bottom">
          <!-- 账号列表 -->
          <div class="v-mian-bottom-list">
            <!-- 项 -->
            <div class="v-main-bottom-item g-flex-align-center" v-for="(item, index) in platformList" :key="index">
              <div class="v-bottom-item-username g-flex-align-center g-flex-1" @click="itemPopClick(item)">
                <i class="iconfont" :class="item.icon"></i>
                <span v-show="item.account!=''">{{item.account}}</span>
                <span v-show="item.account === ''" class="v-no-band" @click.stop="bindItemClick(item)">去绑定</span>
              </div>
              <div class="v-bottom-item-open">
                <van-switch :value="item.checked" active-color="#F9436B" inactive-color="#FCA1B5" size="24px" @input="onInput(item)" />
              </div>
            </div>
          </div>

          <!-- 接单模块 -->
          <div class="v-main-bottom-receive-order g-flex-align-center g-flex-justify-center" v-show="!isWaitOrder">
            <div class="v-receive-btn g-flex-align-center g-flex-justify-center" @click="receiveOrderClick">
              <span>接单</span>
            </div>
          </div>

          <!-- 接单中 -->
          <main-order-wait ref="MainOrderWait" @emitStopOrder="emitStopOrder"/>

        </div>
      </div>

    </div>

    <!-- 筛选接单类型 -->
    <main-order-select-pop ref="MainOrderSelectPop"/>


    <!-- 公告弹窗 -->
    <notice-pop ref="NoticePop"/>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import MainOrderWait from './MainOrderWait.vue'
  import MainOrderSelectPop from './MainOrderSelectPop.vue'
  import NoticePop from '../../components/NoticePop.vue'
  import { apiGetUserInfo, apiGetPlatformAccountList, apiGetAccountList, apiStartTask, apiStopTask, apiGetTotal, apiGetConfig } from '@/utils/api.js'
  export default {
    components: { HeadBack, MainOrderWait, MainOrderSelectPop, NoticePop },
    data() {
      return {
        systemCodeArr: ['BULLETIN'],
        timer: 0,
        platformList: [],  //平台列表
        
        userInfo: this.$global.userInfo, //用户信息

        isWaitOrder: Number(window.localStorage.getItem('isWait')) ? true : false, //是否在等待接单

        totalMoney: {
          today_money: '',
          task_money: '',
          spread_money: '',
          principal: '',
          commission: ''
        }
      }
    },
    async created() {
      await this.apiGetConfigHandel()
      // 获取今日收入数据
      await this.apiGetTotalHandel()
      // 获取用户信息
      await this.apiGetUserInfoHandel()
    },
    mounted() {
      // 通知第一次进来展示一下就行了
      if(!this.$global.isShowNotice) {
        // 如果没通知就不要展示了
        if(this.noticeList[0].bullent) this.$refs.NoticePop.onShow(true)
        this.$global.isShowNotice = true
      }
      // 获取平台列表(以及是否在接单 接单是否过期)
      this.apiGetPlatformAccountListHandel()
    },
    filters: {
      filtersInt(val) {
       return val.substring(0, val.indexOf('.') + 1)
      },
      filtersFloat(val) {
       return val.substring(val.indexOf('.') + 1)
      }
    },
    methods: { 
      // 获取配置
      async apiGetConfigHandel() {
        const { success, data } = await apiGetConfig({ code_arr: this.systemCodeArr })
        if(!success) return
        this.$global.setMd5(data.data.BULLETIN.md5)
        this.$global.config.BULLETIN = data.data.BULLETIN
        console.log('config', this.$global.config)
      },
      // 获取首页 今日收入数据
      async apiGetTotalHandel() {
        const { success, data } = await apiGetTotal()
        if(!success) return
        // principal //本金余额
        // commission //佣金余额
        this.totalMoney = data.data
        console.log(data)
      },
      
      // 获取用户信息
      async apiGetUserInfoHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.userInfo = data.data
        this.$global.setUserInfo(data.data)
        if(this.userInfo.real_status == 1) return this.setIsWait(false, 0, 0)
        if(this.userInfo.real_status != 2) return this.setIsWait(false, 0, 0)
        console.log(data)
      },

      // 获取平台账号列表(后期加功能要用,现在木有用)
      async apiGetAccountListHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetAccountList({ platform_id: this.platformId })
        if(!success) return
        this.accoutList = data.list
        let obj = data.list.find(item => item.active === 1)
        if(!obj) this.selectIndex = -1
        if(obj) this.selectIndex = data.list.indexOf(obj)
        console.log(obj)
      },

      // 获取平台列表 // $global.checkplatlist  本地存的没被开起来的平台id列表
      async apiGetPlatformAccountListHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetPlatformAccountList()
        if(!success) return
        this.platformList = data.list.map(item => {
          if(!this.$global.checkplatlist.includes(item.id) && item.account){
            item.checked = true
          }else {
            item.checked = false
          }
          return item
        })

       // 有一项大于0 就说明还在接单中
        let isWait = this.platformList.some(item => { return (item.task_time * 1000) - new Date().getTime() > 0 })
        if(isWait) {
          let target = this.platformList.find(item => { return (item.task_time * 1000) - new Date().getTime() > 0 } )
          this.setIsWait(true, 1, target.task_time * 1000)
        } 
        else this.setIsWait(false, 0, 0)

        console.log('还在接单吗', isWait)
      },
      // 去绑定点击
      bindItemClick(item) {
        let type = 0
        if(item.id == 1) type = '0' 
        if(item.id == 2) type = '1' 
        if(item.id == 3) type = '2' 
        this.$router.push({ name: 'bindaccountlist', params: { type: type, id: item.id }})
      },

      // 公告每一项点击
      noticeItemClick(item) {
        this.$refs.NoticePop.onShow(true, item)
      },

      // 开关 需要存本地
      onInput(item) {
        // 没账号 去绑定
        if(!item.user_account_id) return this.bindItemClick(item)
        if(this.isWaitOrder) return this.$toast('请停止接单后再选择账号接单')
        this.$set(item, 'checked', !item.checked)
        if(!item.checked) {
          this.$global.setCheckplatlist(item.id)
          // let flag = this.platformList.every(item => {
          //   return !item.checked
          // })
          // if(flag) {
          //   this.$global.setRemoveplatlist()
          //   this.platformList.forEach(item => {
          //     if(item.account) item.checked = true
          //     else item.checked = false
          //   })
          // }
        }else {
          this.$global.setRemoveplatlist(item.id)
        }
      },
      
      // 每一项点击进行绑定
      itemPopClick(item) {
        if(!item.account) return
        this.$refs.MainOrderSelectPop.onShow(item)
      }, 

      // 点击接单
      receiveOrderClick() {
        if(this.userInfo.real_status == 1) return this.$toast('身份信息审核中,预计1个工作日内完成')
        if(this.userInfo.real_status != 2) return this.$router.push({ name: 'changeidcard' })
        if(this.$global.checkplatlist.length == 3) return this.$toast('请选择至少一个平台进行接单')
        let arr = this.platformList.filter(item => item.checked).map(item => item.user_account_id)
        if(!arr.length) return this.$toast('请选择绑定一个账号进行接单')
        this.apiStartTaskHandel(arr)
      },

      // 开始接单
      async apiStartTaskHandel(arr) {
        const { success, data } = await apiStartTask({ ids: arr })
        if(!success) return
        console.log(data)
        this.setIsWait(true, 1, (data.task_time * 1000))
      },

      // 停止接单
      async apiStopTaskHandel() {
        const { success, data } = await apiStopTask()
        if(!success) return
        this.$toast('操作成功')
        this.setIsWait(false, 0, 0)
        clearInterval(this.timer)
        console.log(data)
      },

      // 停止接单
      emitStopOrder() {
        this.apiStopTaskHandel()
      },

      // 设置值 flag 停止接单  num 设置本地isWait time 接单结束时间
      setIsWait(flag, num, time = 0) {
        this.isWaitOrder = flag
        window.localStorage.setItem('isWait', num)
        this.$refs.MainOrderWait.onShow(flag, time)
      }
    },
    computed: {
      noticeList() {
        var bullentObj = this.$global.config.BULLETIN
        if(!bullentObj.bullent) return [ { bullent: '', bullent_title: '' }]
        let flag = this.$global.isShowNotice == 1 || this.$global.isChangeBullent
        console.log(flag)
        console.log(bullentObj)
        if(flag) {
        // 如果没通知就不要展示了
        if(bullentObj.bullent) {
          this.$nextTick(() => {
            this.$refs.NoticePop.onShow(true, bullentObj)
          })
        } 
        this.$global.setShowNotice('0')
      }
        return [bullentObj]
      }
    }
  }
</script>

<style lang="scss">
.v_main {
  height: 100%;
  overflow: auto;
  .v-main-content {
    flex: 1;
    overflow-y: auto;
    background: #F1F1F1;

    .v-main-top {
      background: url('/image/main_top_bg.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 168px;
      .v-main-top-list {
        align-self: stretch;
        .v-main-top-list-item {
          flex: 1;
          color: $white;
          .v-main-top-list-item-title {
            font-size: 30px;
            span {
              font-size: 18px;
            }
          }
          .v-main-top-list-item-value {
            font-size: 30px;
            padding-top: 10px;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }

    .v-main-total-list {
      margin-top: 5px;
      padding: 10px 0;
      background: $main_color;
      color: $white;
      .v-total-item {
        line-height: 1;
        font-size: 14px;
        padding: 0 6px;
        border-right: 1px solid $white;
        &:nth-last-of-type(1) {
          border-right: none;
        }
        span {
          padding: 0 1px;
        }
      }
    }

    .v-notice {
      .van-notice-bar__content {
        width: 100%;
        .v-notice-swipe {
          height: 40px;
          line-height: 40px;
          width: 100%;
          .v-notice-item {
            display: flex;
            justify-content: space-between;
            p {
              flex: 1;
              @include textManyOverflow(1)
            }
          }
        }
      }
    }

    .v-main-bottom-content {
      padding: 12px 10px;
      .v-main-bottom {
        background: $white;
        border-radius: 12px;
        padding: 10px;
        .v-mian-bottom-list {
          .v-main-bottom-item {
            border-bottom: 1px solid #DCDCDC;
            padding: 10px 0;
            .v-bottom-item-username {
              padding-left: 12px;
              .iconfont {
                color: $main_color;
                line-height: 1.3;
                font-size: 20px;
              }
              span {
                letter-spacing: 1px;
                padding-left: 10px;
                flex: 1;
                color: #292929;
                font-size: 14px;
                @include textManyOverflow(1);
                &.v-no-band {
                  color:#909090;
                  text-decoration: underline;
                }
              }
            }
            .v-bottom-item-open {
              padding-left: 20px;
            }
            &:nth-last-of-type(1) {
              .iconfont {
                font-size: 18px;
                padding-left: 2px;
              }
            }
          }
        }

        .v-main-bottom-receive-order {
          padding: 20px 0;
          background: $white;
          .v-receive-btn {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            background: $main_color;
            color: $white;
            font-size: 16px;
          }
        }
      }
    }

  }
}
</style>